import React, { useEffect, useRef } from 'react';
import '../styles/VideoFeed.css';

function VideoFeed() {
    const videoRef = useRef(null);
    const wsRef = useRef(null);

    useEffect(() => {
        wsRef.current = new WebSocket('wss://rgs.bansheeuav.tech/ws');

        wsRef.current.onopen = () => {
            console.log('Connected to WebSocket server for reading frames');
        };

        wsRef.current.onmessage = (event) => {
            console.log('Received data:', event.data);
            if (event.data instanceof ArrayBuffer) {
                const arrayBuffer = event.data;
                const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                if (videoRef.current) {
                    videoRef.current.src = imageUrl;
                }
            } else {
                console.error('Received data is not an ArrayBuffer');
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket closed:', event);
        };

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    return (
        <div className='live-vid-container'>
            <div>
                <img ref={videoRef} alt='vid' />
            </div>
        </div>
    );
}

export default VideoFeed;
